import React, { Component } from "react";
import "./ChartWidgetContainer.scss";
import {
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  createTheme,
  MenuItem,
  Button,
} from "@mui/material";

export class ChartWidgetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalOption: 0,
    };

    this.handleIntervalChange = this.handleIntervalChange.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
  }

  handleIntervalChange(event) {
    this.setState({ intervalOption: event.target.value });
    if (this.props.onIntervalChange) {
      this.props.onIntervalChange(event.target.value);
    }
  }

  render() {
    return (
      <div className="chartwidget-container-component" style={this.props.size}>
        <span className="title">{this.props.title}</span>
        {this.intervalDropdown()}
        <div className="widget">{this.props.children}</div>
      </div>
    );
  }

  intervalDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <Button variant="outlined" className="interval-dropdown" onClick={this.exportCSV}>Export</Button>
        <FormControl className="interval-dropdown" sx={{ minWidth: 100, height: 38 }}>
          <InputLabel id="demo-simple-select-label">Time Interval</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.intervalOption}
            label="Time Interval"
            onChange={this.handleIntervalChange}
          >
            <MenuItem value={0}>Default</MenuItem>
            <MenuItem value={1}>1s</MenuItem>
            <MenuItem value={2}>10s</MenuItem>
            <MenuItem value={3}>30s</MenuItem>
            <MenuItem value={4}>1m</MenuItem>
            <MenuItem value={5}>10m</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }

  exportCSV() {
    var csvContent = this.props.exportData;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
    const objUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', objUrl)
    link.setAttribute('download', `Station${this.props.stationId}_${this.props.title}_${new Date().toISOString()}.csv`)
    document.body.appendChild(link);
    link.click();
  }
}
