import React, { Component } from "react";
import { Plot, newTable, addColumn, InfluxColors } from "@influxdata/giraffe";
import "./Gauge.scss";

export class Gauge extends Component {
  constructor(props) {
    super(props);
  }

  themes = {
    "": {
      //0
      suffix: "",
      minColor: InfluxColors.Honeydew,
      minValue: 0,
      maxColor: InfluxColors.Curacao,
      maxValue: 300,
    },
    voltage: {
      //1
      suffix: "V",
      minColor: InfluxColors.Curacao,
      minValue: 0,
      maxColor: InfluxColors.Honeydew,
      maxValue: 300,
    },
    power: {
      // 2
      suffix: "W",
      minColor: InfluxColors.Honeydew,
      minValue: 0,
      maxColor: InfluxColors.Tiger,
      maxValue: 2700,
    },
    current: {
      // 3
      suffix: "A",
      minColor: InfluxColors.Honeydew,
      minValue: 0,
      maxColor: InfluxColors.Tiger,
      maxValue: 10,
    },
    temperature: {
      // 4
      suffix: "°C",
      minColor: InfluxColors.Tiger,
      minValue: 0,
      maxColor: InfluxColors.Curacao,
      maxValue: 550,
    },
    cycle: {
      // 5
      suffix: "",
      minColor: "#a4ecff",
      minValue: 0,
      maxColor: "#06c7ff",
      maxValue: 550,
    },
  };

  render() {
    const style =
      this.props.showTicks ?? true
        ? {
            width: "100%",
            height: "114%",
            margin: "0px",
          }
        : {
            width: "200px",
            height: "200px",
            margin: "-100px -50px -50px -50px",
          };

    var table = newTable(1)
      .addColumn("_time", "dateTime:RFC3339", "time", [1])
      .addColumn("_value", "system", "number", [this.props.data]);

    var theme = this.themes[this.props.gaugeType ?? ""];
    var gaugeTheme =
      this.props.showTicks ?? true
        ? {
            valuePositionYOffset: 0.9,
          }
        : {
            valuePositionYOffset: 1.1,
            lineCount: 0,
            smallLineCount: 0,
            lineColor: "rgba(0,0,0,0)",
            labelColor: "rgba(0,0,0,0)",
            minLineWidth: 10,
            valueColor: "#3e98c7",
          };

    const config = {
      table: table,
      layers: [
        {
          type: "gauge",
          x: "_time",
          y: "_value",
          suffix: theme.suffix,
          gaugeSize: 4.6,
          decimalPlaces: {
            isEnforced: true,
            digits:
              this.props.gaugeType == "cycle"
                ? 0
                : this.props.showTicks ?? true
                  ? 2
                  : 0,
          },
          gaugeColors: [
            {
              id: 1,
              type: "min",
              hex: theme.minColor,
              name: "1",
              value: theme.minValue,
            },
            {
              id: 2,
              type: "max",
              hex: theme.maxColor,
              name: "2",
              value: this.props.gaugeType == "cycle"
              ? this.props.maxValue : theme.maxValue,
            },
          ],
          gaugeTheme: gaugeTheme,
        },
      ],
    };

    const SimplePlot = React.createElement(Plot, { config }, null);
    return React.createElement("div", { style }, SimplePlot);
  }
}
