import React, { Component } from "react";
// import "./OffsetConfig.scss";
import {
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../../api-authorization/msalconfig";

export class RunType extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);

    this.state = {
      inEditState: false,
      runType: null,
    };

    this.handleRunType = this.handleRunType.bind(this);
    this.toggleEditBtnState = this.toggleEditBtnState.bind(this);
    this.getRunTypeData = this.getRunTypeData.bind(this);
    this.saveRunTypeData = this.saveRunTypeData.bind(this);
  }

  componentDidMount() {
    this.getRunTypeData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stationId != prevProps.stationId ||
      this.props.panelId != prevProps.panelId
    ) {
      this.getRunTypeData();
    }
  }

  render() {
    let buttons = null;
    if (this.state.runType !== null) {
      buttons = this.buttons();
    }
    return buttons;
  }

  buttons() {
    return (
      <div>
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          <FormControl
            sx={{ minWidth: 200, marginTop: "20px", marginBottom: "20px" }}
          >
            <InputLabel id="select-report">Select Run Type</InputLabel>
            <Select
              labelId="select-runtype-label"
              id="select-runtype"
              multiple={false}
              label="Select Run Type"
              value={this.state.runType}
              onChange={this.handleRunType}
              disabled={!this.state.inEditState}
            >
              <MenuItem value={1}>Dry mode</MenuItem>
              <MenuItem value={2}>Steam Mode</MenuItem>
              <MenuItem value={3}>Eco Steam</MenuItem>
            </Select>
          </FormControl>
          <Button
            sx={{ marginTop: "20px", marginRight: "15px", height: "55px" }}
            variant="outlined"
            onClick={this.toggleEditBtnState}
          >
            {this.state.inEditState ? "Cancel" : "Edit"}
          </Button>
          <Button
            sx={{ marginTop: "20px", height: "55px" }}
            variant="outlined"
            onClick={this.saveRunTypeData}
            disabled={!this.state.inEditState}
          >
            Save
          </Button>
        </ThemeProvider>
      </div>
    );
  }

  handleRunType(event) {
    this.setState({ runType: event.target.value });
  }
  toggleEditBtnState() {
    if (this.state.inEditState) {
      this.setState({
        inEditState: false,
      });
    } else {
      var original = this.state.originalState;
      this.setState({
        inEditState: true,
      });
    }
  }

  async saveRunTypeData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          panelId: this.props.panelId,
          stationId: this.props.stationId,
          value: this.state.runType,
          time: null,
        }),
      };

      const response = await fetch("data/SetRunTypeData", requestOptions);
      const responseData = await response.json();
      this.setState({
        inEditState: false,
        runType: responseData.value,
      });
    }
  }
  async getRunTypeData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          panelId: this.props.panelId,
          stationId: this.props.stationId,
          value: 0,
          time: null,
        }),
      };
      const response = await fetch("data/GetRunTypeData", requestOptions);
      var responseData = await response.json();
      this.setState({ runType: responseData.value });
    }
  }
}
