import React, { Component } from "react";
import "./Rules.scss";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";
import {
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  createTheme,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { _appConfigDefaultLocation, _appConfigPanels } from "../../appConfig";

export class Rules extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);

    this.state = {
      rules: [],
      addRule: false,
      createRuleRequest: {
        name: "",
        locationId: _appConfigDefaultLocation,
        panelId: 1,
        stationId: 1,
        thresholdValue: 0,
        type: 1,
        field: 0,
      },
    };

    this.getRuleData = this.getRuleData.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.createRule = this.createRule.bind(this);

    this.toggleAddRule = this.toggleAddRule.bind(this);
    this.handleStationSelection = this.handleStationSelection.bind(this);
    this.handleRuleTypeSelection = this.handleRuleTypeSelection.bind(this);
    this.handleFieldSelection = this.handleFieldSelection.bind(this);
    this.handleRuleName = this.handleRuleName.bind(this);
    this.handleThreshold = this.handleThreshold.bind(this);
    this.handlePanelChange = this.handlePanelChange.bind(this);
  }
  componentDidMount() {
    this.getRuleData();
  }

  render() {
    var displayList = [];
    displayList.push(this.buttons());
    if (this.state.addRule == true) {
      displayList.push(this.createRuleOptions());
      if (this.state.createRuleRequest.name) {
        displayList.push(this.createRuleButton());
      }
    }
    if (this.state.rules && this.state.rules.length > 0) {
      var data = this.dataGrid();
      displayList.push(data);
    }
    return <div className="notification-page-container">{displayList}</div>;
  }
  buttons() {
    return (
      <div>
        <Button
          sx={{ marginTop: "15px", height: "40px" }}
          variant="outlined"
          onClick={this.toggleAddRule}
        >
          {this.state.addRule ? "Cancel" : "Add Rule"}
        </Button>
      </div>
    );
  }
  dataGrid() {
    var items = this.state.rules;
    var btnStyle = { width: 300, height: "15px", marginRight: "10px" };

    if (!items && items.length <= 0) {
      return <h3>No Rules created found</h3>;
    }

    var mappingItems = items.map((item, i) => {
      return (
        <div style={{ marginTop: "15px" }}>
          <TextField
            label="Rule Name"
            sx={btnStyle}
            id={"btn1-" + i}
            disabled
            value={item.name}
          />
          <TextField
            label="Creation Date"
            sx={btnStyle}
            disabled
            id={"creationdate-" + i}
            value={item.time}
          />
          <Button
            sx={{ height: "55px" }}
            variant="outlined"
            onClick={() => this.deleteRule(item.id)}
          >
            Delete
          </Button>
        </div>
      );
    });

    return (
      <>
        <h3 style={{ color: "rgba(255, 255, 255, 0.7)", marginTop: "40px" }}>
          Existing Rules
        </h3>
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          {mappingItems}
        </ThemeProvider>
      </>
    );
  }
  createRuleOptions() {
    var name = this.name();
    var panelDropdown = this.panelDropdown();
    var stationDropdown = this.stationDropdown();
    var fieldDropdown = this.fieldDropdown();
    var ruleTypeDropdown = this.ruleTypeDropdown();
    var threshold = this.threshold();

    return (
      <div>
        <div>
          {name}
          {panelDropdown}
          {stationDropdown}
        </div>
        <div>
          {fieldDropdown}
          {ruleTypeDropdown}
          {threshold}
        </div>
      </div>
    );
  }
  name() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <TextField
          sx={{ marginTop: "20px", marginRight: "10px" }}
          id="handleRuleName-input-1"
          label="Rule Name"
          onChange={this.handleRuleName}
          value={this.state.createRuleRequest.name}
          maxRows={4}
        />
      </ThemeProvider>
    );
  }
  panelDropdown() {
    var panels = [];
    _appConfigPanels.map((element) => {
      panels.push(<MenuItem value={element}>{element}</MenuItem>);
    });
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{
            minWidth: 200,
            marginTop: "20px",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        >
          <InputLabel id="demo-simple-select-label">Select Panel</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.createRuleRequest.panelId}
            label="Select Panel"
            onChange={this.handlePanelChange}
          >
            {panels}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  stationDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl sx={{ minWidth: 200, marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-label">Select Stations</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Stations"
            value={this.state.createRuleRequest.stationId}
            onChange={this.handleStationSelection}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  fieldDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{ minWidth: 200, marginTop: "20px", marginRight: "10px" }}
        >
          <InputLabel id="demo-simple-select-label">Select field</InputLabel>
          <Select
            labelId="fieldDropdown"
            id="fieldDropdown"
            label="Select field"
            value={this.state.createRuleRequest.field}
            onChange={this.handleFieldSelection}
          >
            <MenuItem value={0}>Voltage</MenuItem>
            <MenuItem value={1}>Current</MenuItem>
            <MenuItem value={2}>Power</MenuItem>
            <MenuItem value={3}>Temperature</MenuItem>
            <MenuItem value={4}>Fault</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  ruleTypeDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{ minWidth: 200, marginTop: "20px", marginRight: "10px" }}
        >
          <InputLabel id="demo-simple-select-label">
            Select rule type
          </InputLabel>
          <Select
            labelId="ruleTypeDropdown"
            id="ruleTypeDropdown"
            label="Select rule type"
            value={this.state.createRuleRequest.type}
            onChange={this.handleRuleTypeSelection}
          >
            <MenuItem value={0}>Less than</MenuItem>
            <MenuItem value={1}>Greater than</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  threshold() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <TextField
          sx={{ marginTop: "20px" }}
          id="handleThreshold-input-1"
          label="Threhold Value"
          onChange={this.handleThreshold}
          type="number"
          value={this.state.createRuleRequest.thresholdValue}
          maxRows={4}
        />
      </ThemeProvider>
    );
  }
  createRuleButton() {
    return (
      <div>
        <Button
          sx={{ marginTop: "15px", height: "40px" }}
          variant="outlined"
          onClick={this.createRule}
        >
          Create Rule
        </Button>
      </div>
    );
  }

  toggleAddRule() {
    this.setState({ addRule: !this.state.addRule });
  }
  handlePanelChange(event) {
    var panelId = event.target.value;
    var request = this.state.createRuleRequest;
    request.panelId = panelId;
    this.setState({
      createRuleRequest: request,
    });
  }
  handleStationSelection(event) {
    var stationId = event.target.value;
    var request = this.state.createRuleRequest;
    request.stationId = stationId;
    this.setState({
      createRuleRequest: request,
    });
  }
  handleRuleTypeSelection(event) {
    var ruleType = event.target.value;
    var request = this.state.createRuleRequest;
    request.type = ruleType;
    this.setState({
      createRuleRequest: request,
    });
  }
  handleFieldSelection(event) {
    var field = event.target.value;
    var request = this.state.createRuleRequest;
    request.field = field;
    this.setState({
      createRuleRequest: request,
    });
  }
  handleRuleName(event) {
    var name = event.target.value;
    var request = this.state.createRuleRequest;
    request.name = name;
    this.setState({
      createRuleRequest: request,
    });
  }
  handleThreshold(event) {
    var threshold = parseFloat(event.target.value);
    var request = this.state.createRuleRequest;
    request.thresholdValue = threshold;
    this.setState({
      createRuleRequest: request,
    });
  }

  async createRule() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(this.state.createRuleRequest),
      };
      const response = await fetch("data/CreateRule", requestOptions);
      var responseData = await response.json();
      this.setState({ rules: responseData, addRule: false });
    }
  }
  async getRuleData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      };
      const response = await fetch("data/GetRules", requestOptions);
      var responseData = await response.json();
      this.setState({ rules: responseData });
    }
  }
  async deleteRule(checkId) {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      };
      const response = await fetch(
        `data/DeleteRule?checkId=${checkId}`,
        requestOptions
      );
      var responseData = await response.json();
      this.setState({ rules: responseData });
    }
  }
}
