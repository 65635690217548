import React, { Component } from "react";
import "./StationDrillthrough.scss";
import { SingleValueWidgets } from "./SingleValueWidgets/SingleValueWidgets";
import { ChartWidgets } from "./ChartWidgets/ChartWidgets";
import { withRouter } from "./../../config/WithRouter";
import { DateRange } from "../shared/DateRange/DateRange";
import {
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  createTheme,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { withMsal } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";
import { RunType } from "./RunType/RunType";
import { _appConfigPanels } from "../../appConfig";

class StationDrillthrough extends Component {
  constructor(props) {
    super(props);
    var panelId = 1;
    var stationId = 1;
    
    var paramPanelId = this.props.params.panelId;
    var paramStationId = this.props.params.stationId;

    if (paramPanelId == 0 || paramStationId == 0){
      panelId = localStorage.getItem(`panelId`);
      stationId = localStorage.getItem(`stationId`) ?? 1;
    }
    else {
      panelId = paramPanelId;
      localStorage.setItem(`panelId`, paramPanelId);
      stationId = paramStationId;
      localStorage.setItem(`stationId`, stationId);
    }

    
    this.state = {
      panelId: panelId,
      station: stationId,
      testStart: null,
      from: null,
      to: null,
      timeRangeOption: 8,
      testStartList: null,
      autoRefresh: true,
    };
    this.fetchTestStartInfo(panelId, stationId);
    this.handlePanelChange = this.handlePanelChange.bind(this);
    this.handlStationChange = this.handlStationChange.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handlTestStartChange = this.handlTestStartChange.bind(this);
    this.handleAutoRefresh = this.handleAutoRefresh.bind(this);
  }

  render() {
    let stationMenu = [
      this.panelDropdown(),
      this.stationDropdown(),
      <DateRange
        timeRangeOption={this.state.timeRangeOption}
        handleChangeOption={this.handleChangeOption}
        handleChangeFrom={this.handleChangeFrom}
        handleChangeTo={this.handleChangeTo}
      />,
      <FormControlLabel
        control={
          <Switch
            checked={this.state.autoRefresh}
            onChange={this.handleAutoRefresh}
          />
        }
        label="Auto Refresh Charts"
      />,
      <RunType panelId={this.state.panelId} stationId={this.state.station} />,
    ];

    let singleValueWidgets;
    let chartWidgets;
    if (this.state.station && this.state.testStart) {
      singleValueWidgets = (
        <SingleValueWidgets
          panelId={this.state.panelId}
          station={this.state.station}
        />
      );
      chartWidgets = (
        <ChartWidgets
          panelId={this.state.panelId}
          station={this.state.station}
          testStart={this.state.testStart}
          autoRefresh={this.state.autoRefresh}
          from={this.state.from}
          to={this.state.to}
          timeRangeOption={this.state.timeRangeOption}
        />
      );
    }

    return (
      <div className="station-drillthrough-container">
        <h1>Station Analytics</h1>
        <div className="station-dropdown">{stationMenu}</div>
        <div className="widget-container">{singleValueWidgets}</div>
        <div className="widget-container">{chartWidgets}</div>
      </div>
    );
  }

  panelDropdown() {
    var panels = [];
    _appConfigPanels.map((element) => {
      panels.push(<MenuItem value={element}>{element}</MenuItem>);
    });
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="demo-simple-select-label">Select Panel</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.panelId}
            label="Select Panel"
            onChange={this.handlePanelChange}
          >
            {panels}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  handlePanelChange(event) {
    this.setState({ panelId: event.target.value });
    this.setState({ autoRefresh: true });
    window.location = `/details/1/${event.target.value}/${this.state.stationId ?? 1}`;
    this.fetchTestStartInfo(event.target.value, this.state.station);
  }

  stationDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="demo-simple-select-label">Select Station</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.station}
            label="Select Station"
            onChange={this.handlStationChange}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>

        {this.state.testStartList && (
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">
              Select Test start
            </InputLabel>
            <Select
              labelId="select-test-start"
              id="select-test-start"
              value={this.state.testStart}
              label="Select Test start"
              onChange={this.handlTestStartChange}
            >
              {this.state.testStartList.map((opt) => (
                <MenuItem value={opt}>
                  {opt === "0-All"
                    ? "All"
                    : new Date(parseInt(opt) * 1000).toLocaleString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </ThemeProvider>
    );
  }
  handlStationChange(event) {
    this.setState({ station: event.target.value });
    this.setState({ autoRefresh: true });
    window.location = `/details/1/${this.state.panelId ?? 1}/${event.target.value}`;

    this.fetchTestStartInfo(this.state.panelId, event.target.value);
  }
  handlTestStartChange(event) {
    this.setState({ testStart: event.target.value });
  }
  handleChangeOption(event) {
    this.setState({
      timeRangeOption: event.target.value,
    });
  }
  handleChangeFrom(val) {
    this.setState({
      from: val,
    });
  }
  handleChangeTo(val) {
    this.setState({
      to: val,
    });
  }
  handleAutoRefresh(event) {
    this.setState({
      autoRefresh: event.target.checked,
    });
  }

  async fetchTestStartInfo(panelId, stationId) {
    const msalInstance = this.props.msalContext.instance;
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.props.msalContext.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/csv",
        },
        method: "GET",
      };

      const response = await fetch(
        `data/GetTestStartValues?stationId=${stationId}&panelId=${panelId}`,
        requestOptions
      );

      const result = await response.json();
      try {
        if (result.length > 1) {
          this.setState({ testStart: result[0] });
          this.setState({ testStartList: result });
        }
      } catch (error) {
        console.error("error", error.message);
      }
    }
  }
}

export default withRouter(withMsal(StationDrillthrough));
