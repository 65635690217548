import React, { Component } from "react";
import "./Notifications.scss";
import { ThemeProvider, createTheme, Button, Link } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";

export class Notifications extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };

    this.getNotificationData = this.getNotificationData.bind(this);
  }

  componentDidMount() {
    this.getNotificationData();
  }

  render() {
    var buttons = this.buttons();
    var data = null;
    if (this.state.notifications && this.state.notifications.length) {
      data = this.dataGrid();
    }
    var noDataMessage = (
      <h4
        style={{
          color: "rgba(255, 255, 255, 0.5)",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        No notifications found
      </h4>
    );
    return (
      <div className="notification-page-container">
        <h3
          style={{
            color: "rgba(255, 255, 255, 0.7)",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          Notification history
        </h3>

        {buttons}
        {data && data.length != 0 ? data : noDataMessage}
      </div>
    );
  }
  buttons() {
    return (
      <div>
        <Link href="/rules" underline="hover">
          {"Manage Rules"}
        </Link>
      </div>
    );
  }
  dataGrid() {
    if (!this.state.notifications || this.state.notifications.length <= 0) {
      return <h3>No data found</h3>;
    }

    const columns = [
      { field: "time", type: "datetime", headerName: "Time", width: 200 },
      { field: "notificationName", headerName: "Rule Name", width: 250 },
      {
        field: "locationId",
        headerName: "LocationID",
        type: "number",
        width: 100,
      },
      {
        field: "panelId",
        headerName: "PanelID",
        type: "number",
        width: 100,
      },
      {
        field: "stationId",
        headerName: "StationID",
        type: "number",
        width: 100,
      },
      {
        field: "field",
        headerName: "Metric",
        width: 200,
      },
      {
        field: "value",
        headerName: "Value",
        width: 100,
        type: "number",
      },
    ];

    const rows = this.state.notifications.map((item, index) => {
      return {
        id: index,
        ...item,
      };
    });

    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <div style={{ height: 650, width: "1000px", marginTop: "30px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20]}
          />
        </div>
      </ThemeProvider>
    );
  }

  async getNotificationData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      };
      const response = await fetch("data/GetNotifications", requestOptions);
      var responseData = await response.json();

      this.setState({ notifications: responseData });
    }
  }
}
