import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import "./LocalSpinner.scss";

export class LocalSpinner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="spinner-container">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
}
