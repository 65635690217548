import React, { Component } from "react";

export class About extends Component {
  render() {
    return (
      <div
        style={{
          margin: "20px 0 0 0",
        }}
      >
        <h1>Station monitoring system</h1>
        <p>
          Welcome to the station monitoring system brought to you by Starwatt,
          this application contains the following pages
        </p>
        <ul>
          <li>
            <strong>Dashboard</strong>: Get an overview of all the stations in a
            single view
          </li>
          <li>
            <strong>Station Analytics</strong>: Detailed view of KPIs of each
            station
          </li>
          <li>
            <strong>Reports</strong>: Generate reports based on the data
            collected from stations
          </li>
          <li>
            <strong>For Raw Data Report: </strong>
            You can extract only RAW data for max 7 days at once for last 120
            days, You can select date range for 7 days and download the report.
            Then again for next 7 days and download. 
            <br/>Report download will take
            upto 240 seconds, so do not refresh the page for next 240 seconds A
            combined 120-day data download is not possible because of our
            computer's less processing power than a server.
            <br/>
          </li>
          <li>
            <strong>Comparison</strong>: Compare details of two stations for
            analytical purposes
          </li>
          <li>
            <strong>Offsets</strong>: Set offsets values for various metrics
            that are collected
          </li>
          <li>
            <strong>Notification</strong>: Set up notifications that are
            delivered to the mobile application and email
          </li>
        </ul>

        <br />
        <br />
        <h2>Standard Operating Procedure</h2>
        <br />
        <p>
          <h4>After Turning the Main Power ON</h4>
          <ol>
            <li>Turn ON the UPS from by pressing Power Button.</li>
            <li>Turn ON the Edge Gateway by pressing power button.</li>
            <li>Turn ON the UPS by pressing Power Button for Panel Devices.</li>
            <li>
              Turn ON All Starwatt Machine Monitoring Station Devices if not
              turned by Turing on Power Button. The devices should Turn ON and
              wait for the Blue LED turn on as it confirms the connection to the
              Wi-Fi Network.
            </li>
            <li>Turn ON the Panel from MCB.</li>
          </ol>
        </p>
        <br />

        <p>
          <h4>Before Turning the Main Power OFF</h4>
          <ol>
            <li>Turn OFF the Panel from MCB.</li>
            <li>
              Turn OFF All Starwatt Machine Monitoring Devices with dedicated
              Power Buttons.
            </li>
            <li>Turn OFF the UPS by pressing the Power Button.</li>
            <li>Turn OFF the Edge Gateway by pressing the power button.</li>
            <li>Turn OFF the UPS by pressing the Power Button.</li>
          </ol>
        </p>
        <br />

        <p>
          <h4>Starting The Test</h4>
          <p>
            Long press the START button On the Panel for the Desired station for
            3 seconds to Start the Test and enable Logging.
          </p>
        </p>
        <br />

        <p>
          <h4>Stopping The Test</h4>
          <p>
            Long Press the Reset Button of the Panel on the Desired Station for
            3 seconds to Stop the Test, Reset the counters and Disable logging.
          </p>
        </p>
        <br />

        <p>
          <br />
          <h4>LED Indications on Logger</h4>
          <p>
            <strong>
              <span style={{ color: "white" }}>White</span>
            </strong>
            : Indicates the Power on Status.
          </p>
          <p>
            <strong>
              <span style={{ color: "blue" }}>Blue</span>
            </strong>
            : Indicates if successful Wi-Fi connection.
          </p>
          <p>
            <strong>
              <span style={{ color: "green" }}>Green</span>
            </strong>
            : Blinks in every 1 Second to confirm successful delivery of Data to
            Edge Gateway.
          </p>
          <p>
            <strong>
              <span style={{ color: "green" }}>Green</span>
            </strong>
            : Indicates the Test Status and successful Data Logging enabled.
          </p>
          <p>
            <strong>
              <span style={{ color: "red" }}>Red</span>
            </strong>
            : Indicates Detection of Fault E1, E2, E3 E4.
          </p>
        </p>
        <br />

        <p>
          <h4>Steps to Clear the Fault</h4>
          <ul>
            <li>Clear the fault by changing the IRON.</li>
            <li>
              Long Press the Acknowledge Button on panel to turn the buzzer OFF.
            </li>
          </ul>
        </p>
        <br />

        <p>
          <h4>General Specifications of the Logger</h4>
          <p>Max Voltage on PT coil : 320V</p>
          <p>PT FUSE: 2A Glass Cartridge fuse</p>
          <p>WiFi Frequency : 2.4GHz</p>
          <p>Power Requirement: 220v</p>
          <p>Max Current on CT coil : 25A</p>
        </p>
        <br />
      </div>
    );
  }
}
