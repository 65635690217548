import React, { Component } from "react";
import "./OffsetConfig.scss";
import { ThemeProvider, createTheme, Button, TextField } from "@mui/material";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";

export class OffsetConfig extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);

    this.state = {
      inEditState: false,
      originalState: [],
      displayState: [],
    };

    this.toggleEditBtnState = this.toggleEditBtnState.bind(this);
    this.getOffsetData = this.getOffsetData.bind(this);
    this.saveOffsetData = this.saveOffsetData.bind(this);
    this.handleVoltage = this.handleVoltage.bind(this);
    this.handleCurrent = this.handleCurrent.bind(this);
    this.handlePower = this.handlePower.bind(this);
    this.handleTemperature = this.handleTemperature.bind(this);
    this.handleE1 = this.handleE1.bind(this);
    this.handleE2 = this.handleE2.bind(this);
    this.handleE3 = this.handleE3.bind(this);
    this.handleE4 = this.handleE4.bind(this);
  }

  componentDidMount() {
    this.getOffsetData();
  }

  render() {
    var buttons = this.buttons();
    var data = this.dataGrid();
    return (
      <div className="offsetconfig-page-container">
        <h3 style={{ color: "rgba(255, 255, 255, 0.7)", marginTop: "40px" }}>
          Offset configuration
        </h3>
        {buttons}
        {data}
      </div>
    );
  }
  buttons() {
    return (
      <div>
        <Button
          sx={{ marginTop: "15px", marginRight: "15px", height: "40px" }}
          variant="outlined"
          onClick={this.toggleEditBtnState}
        >
          {this.state.inEditState ? "Cancel" : "Edit"}
        </Button>
        <Button
          sx={{ marginTop: "15px", height: "40px" }}
          variant="outlined"
          onClick={this.saveOffsetData}
          disabled={!this.state.inEditState}
        >
          Save
        </Button>
      </div>
    );
  }
  dataGrid() {
    var items = this.state.inEditState
      ? this.state.displayState
      : this.state.originalState;
    var btnStyle = { width: 100, height: "20px" };
    var disableEditing = !this.state.inEditState;
    if (items.length > 0) {
      var mappingItems = items.map((item, i) => {
        return (
          <div style={{ marginTop: "15px" }}>
            <TextField
              label="Panel"
              sx={{ width: 70}}
              id={"btn1-" + i}
              disabled
              value={item.key.panelId}
            />
            <TextField
              label="Station"
              sx={{ width: 70, marginRight: "80px" }}
              id={"btn1-" + i}
              disabled
              value={item.key.stationId}
            />
            <TextField
              label="Voltage"
              sx={btnStyle}
              type="number"
              id={"voltage-" + i}
              disabled={disableEditing}
              value={item.value.voltage}
              onChange={(e) => this.handleVoltage(i, e)}
            />
            <TextField
              label="Current"
              sx={btnStyle}
              type="number"
              id={"current-" + i}
              disabled={disableEditing}
              value={item.value.current}
              onChange={(e) => this.handleCurrent(i, e)}
            />
            <TextField
              label="Power"
              sx={btnStyle}
              type="number"
              id={"power-" + indexedDB}
              disabled={disableEditing}
              value={item.value.power}
              onChange={(e) => this.handlePower(i, e)}
            />
            <TextField
              label="Temperature"
              sx={btnStyle}
              id={"temp-" + i}
              type="number"
              disabled={disableEditing}
              value={item.value.temperature}
              onChange={(e) => this.handleTemperature(i, e)}
            />
            <TextField
              label="E1"
              sx={btnStyle}
              id={"e1-" + i}
              type="number"
              disabled={disableEditing}
              value={item.value.e1}
              onChange={(e) => this.handleE1(i, e)}
            />
            <TextField
              label="E2"
              sx={btnStyle}
              id={"e2-" + i}
              type="number"
              disabled={disableEditing}
              value={item.value.e2}
              onChange={(e) => this.handleE2(i, e)}
            />
            <TextField
              label="E3"
              sx={btnStyle}
              id={"e3-" + i}
              type="number"
              disabled={disableEditing}
              value={item.value.e3}
              onChange={(e) => this.handleE3(i, e)}
            />
            <TextField
              label="E4"
              sx={btnStyle}
              id={"e4-" + i}
              type="number"
              disabled={disableEditing}
              value={item.value.e4}
              onChange={(e) => this.handleE4(i, e)}
            />
          </div>
        );
      });
    }

    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        {mappingItems}
      </ThemeProvider>
    );
  }

  toggleEditBtnState() {
    if (this.state.inEditState) {
      this.setState({
        inEditState: false,
        displayState: null,
      });
    } else {
      var original = this.state.originalState;
      this.setState({
        inEditState: true,
        displayState: JSON.parse(JSON.stringify(original)),
      });
    }
  }

  handleVoltage(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.voltage = event.target.value;
    this.setState({ displayState: mapping });
  }
  handleCurrent(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.current = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }
  handlePower(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.power = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }
  handleTemperature(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.temperature = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }
  handleE1(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.e1 = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }
  handleE2(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.e2 = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }
  handleE3(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.e3 = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }
  handleE4(index, event) {
    var mapping = this.state.displayState;
    mapping[index].value.e4 = event.target.value;
    this.setState({ stationCycleMapping: mapping });
  }

  async saveOffsetData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(this.state.displayState),
      };
      const response = await fetch("data/SetOffsetMapping", requestOptions);
      const responseData = await response.json();
      this.setState({
        inEditState: false,
        originalState: responseData,
        displayState: [],
      });
    }
  }
  async getOffsetData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      };
      const response = await fetch("data/GetOffsetMapping", requestOptions);
      var responseData = await response.json();

      this.setState({ originalState: responseData });
    }
  }
}
