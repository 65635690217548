import React, { Component } from "react";
import "./StatusIndicator.scss";

export class StatusIndicator extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  mapping(status) {
    if (status === 0) return "stopped";
    else if (status === 1) return "completed";
    else if (status === 2) return "failed";
    else return "stopped";
  }

  render() {
    return (
      <div className="status-indicator-container">
        <span
          className={`status-indicator dot ${this.mapping(this.props.status)} ${
            this.props.size
          }`}
        >
          {this.props.hoverMessage && (
          <span className="tooltiptext">{this.props.hoverMessage}</span>
          )}
        </span>
      </div>
    );
  }
}