import React, { Component } from "react";
import "./SingleValueWidgets.scss";
import { StatusIndicator } from "../../widgets/StatusIndicator/StatusIndicator";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { WidgetContainer } from "../../shared/WidgetContainer/WidgetContainer";
import { Gauge } from "../../widgets/Gauge/Gauge";
import { ErrorCounter } from "../../widgets/ErrorCounter/ErrorCounter";
import { _devmode_global } from "../../../appConfig";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../../api-authorization/msalconfig";

const REASONABLE_API_REFRESH_RATE = 10000;
const _devMode = _devmode_global;

export class SingleValueWidgets extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      panelId: this.props.panelId,
      stationId: this.props.station,
      details: {},
    };
  }

  componentDidMount() {
    this.fetchStationData();
    if (!_devMode) {
      const intervalID = setInterval(() => {
        this.fetchStationData();
      }, REASONABLE_API_REFRESH_RATE);

      this.setState({ interval: intervalID });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.station != state.stationId
      || props.panelId != state.panelId) {
      return {
        panelId: props.panelId,
        stationId: props.station,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.station != prevProps.station ||
      this.props.panelId != prevProps.panelId
      ) {
      this.fetchStationData();
    }
  }

  standardSize = {
    height: 250,
    width: "24%",
    minWidth: 400,
  };

  smallSize = {
    height: 250,
    width: "15.5%",
    minWidth: 275,
  };

  faultCodeMapping(code) {
    if (code === 0) return "E0: No issues detected";
    if (code === 1) return "E1: Electricity failure";
    if (code === 2) return "E2: Iron failure";
    if (code === 3) return "E3: Temperature over limit (over 290°C)";
    if (code === 4) return "E4: Over current";
    else return "Info not available";
  }

  testStatusMapping(status) {
    if (status === 0) return "OFF";
    if (status === 1) return "ON";
    else return "--";
  }

  render() {
    var testStatusMessage = `Test Status: ${this.testStatusMapping(
      this.state.details.status
    )}`;
    var cycleStatusMessage = `Cycle Status: ${this.testStatusMapping(
      this.state.details.cycleStatus
    )}`;

    return (
      <>
        <WidgetContainer size={this.smallSize} title={testStatusMessage}>
          <StatusIndicator
            size="large"
            status={this.state.details.status}
            hoverMessage={testStatusMessage}
          />
        </WidgetContainer>

        <WidgetContainer size={this.smallSize} title={cycleStatusMessage}>
          <StatusIndicator
            size="large"
            status={this.state.details.cycleStatus}
            hoverMessage={cycleStatusMessage}
          />
        </WidgetContainer>

        <WidgetContainer size={this.smallSize} title="Fault">
          <StatusIndicator
            size="large"
            status={this.state.details.faultStatus == 0 ? 1 : 2}
            hoverMessage={this.faultCodeMapping(this.state.details.faultStatus)}
          />
        </WidgetContainer>

        <WidgetContainer size={this.smallSize} title="Error Counter">
          <ErrorCounter
            ec1={this.state.details.ec1}
            ec2={this.state.details.ec2}
            ec3={this.state.details.ec3}
            ec4={this.state.details.ec4}
          />
        </WidgetContainer>

        <WidgetContainer size={this.smallSize} title="Station Cycle">
          <Gauge
            data={this.state.details.cycleCount ?? 0}
            gaugeType={"cycle"}
            maxValue={this.state.details.cycleCountTotal}
          />
        </WidgetContainer>

        <WidgetContainer size={this.smallSize} title="Thermostat Cycle">
          <Gauge
            data={this.state.details.thermostatCycle ?? 0}
            gaugeType={"cycle"}
            maxValue={25000}
          />
        </WidgetContainer>

        <WidgetContainer size={this.standardSize} title="Voltage">
          <Gauge data={this.state.details.voltage} gaugeType={"voltage"} />
        </WidgetContainer>

        <WidgetContainer size={this.standardSize} title="Power">
          <Gauge data={this.state.details.power} gaugeType={"power"} />
        </WidgetContainer>

        <WidgetContainer size={this.standardSize} title="Current">
          <Gauge data={this.state.details.current} gaugeType={"current"} />
        </WidgetContainer>

        <WidgetContainer size={this.standardSize} title="Temperature">
          <Gauge
            data={this.state.details.temperature}
            gaugeType={"temperature"}
          />
        </WidgetContainer>
      </>
    );
  }

  async fetchStationData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      };
      const response = await fetch(
        `data/GetDashboardData?stationId=${this.state.stationId}&panelId=${this.state.panelId}`,
        requestOptions
      );
      const responseData = await response.json();
      const details = responseData[0];
      this.setState({ details: details ?? {} });
    }
  }
}
