import React, { Component } from "react";
import "./Report.css";
import {
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  createTheme,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { DateRange } from "../shared/DateRange/DateRange";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";
import { LocalSpinner } from "../shared/LocalSpinner/LocalSpinner";
import {
  _devmode_global,
  _appConfigStations,
  _appConfigPanels,
  _appConfigDefaultLocation,
} from "../../appConfig";

export class Reports extends Component {
  static contextType = MsalContext;
  static reportTypes = ["Basic", "Summary"];
  stationList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(props) {
    super(props);

    this.state = {
      locationId: _appConfigDefaultLocation,
      panelId: 1,
      stations: [1],
      timeRangeOption: 8,
      from: null,
      to: null,
      reportType: null,
      testDetails: null,
      comments: null,
      isLoadingRawReport: false,
      isLoadingSummaryReport: false,

      cycles: null,
      mainCycle: "",
      skipNSeconds: 600,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handleStationSelection = this.handleStationSelection.bind(this);
    this.handleTestDetails = this.handleTestDetails.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.fetchRawReportData = this.fetchRawReportData.bind(this);
    this.fetchCyclesData = this.fetchCyclesData.bind(this);
    this.handleMainCycle = this.handleMainCycle.bind(this);
    this.createStationCycleMapping = this.createStationCycleMapping.bind(this);
    this.handleStationMappingCycle = this.handleStationMappingCycle.bind(this);
    this.verifyCyclesData = this.verifyCyclesData.bind(this);
    this.fetchSummaryReportData = this.fetchSummaryReportData.bind(this);
    this.handleSkipNSeconds = this.handleSkipNSeconds.bind(this);
    this.handleReportType = this.handleReportType.bind(this);
    this.handlePanelChange = this.handlePanelChange.bind(this);
  }

  componentDidMount() {}

  renderReportType() {
    return (
      <div>
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          <FormControl
            sx={{ minWidth: 200, marginTop: "20px", marginBottom: "20px" }}
          >
            <InputLabel id="select-report">Select Report Type</InputLabel>
            <Select
              labelId="select-report-label"
              id="select-report"
              multiple={false}
              label="Select Report Type"
              value={this.state.reportType}
              onChange={this.handleReportType}
            >
              <MenuItem value={1}>Basic Report</MenuItem>
              <MenuItem value={2}>Summary Report</MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
      </div>
    );
  }
  basicReportTypeForm() {
    var panelDropdown = this.panelDropdown();
    var dropdown = this.stationDropdown();
    var dates = (
      <div>
        <DateRange
          timeRangeOption={this.state.timeRangeOption}
          handleChangeOption={this.handleChangeOption}
          handleChangeFrom={this.handleChangeFrom}
          handleChangeTo={this.handleChangeTo}
        />
      </div>
    );
    var testDetails = this.testDetails();
    var result = [panelDropdown, dropdown, dates, testDetails];

    if (
      this.state.reportType &&
      this.state.stations.length > 0 &&
      (this.state.timeRangeOption !== 0 || (this.state.to && this.state.from))
    ) {
      result.push(this.renderGenerateReportButton());
    }

    return result;
  }
  summaryReportTypeForm() {
    var panelDropdown = this.panelDropdown();
    var dropdown = this.stationDropdown();
    var dates = (
      <div>
        <DateRange
          timeRangeOption={this.state.timeRangeOption}
          handleChangeOption={this.handleChangeOption}
          handleChangeFrom={this.handleChangeFrom}
          handleChangeTo={this.handleChangeTo}
        />
      </div>
    );
    var testDetails = this.testDetails();
    var skipNSeconds = this.skipNSecondsInput();
    var result = [panelDropdown, testDetails, dropdown, dates, skipNSeconds];
    if (
      this.state.reportType &&
      this.state.stations.length > 0 &&
      (this.state.timeRangeOption !== 0 || (this.state.to && this.state.from))
    ) {
      var fetchCycleBtn = this.fetchCyclesButton();
      result.push(fetchCycleBtn);

      if (this.state.cycles) {
        var cycles = <div>{this.cyclesDropdown()}</div>;
        result.push(cycles);

        if (
          this.state.mainCycle &&
          this.state.stationCycleMapping &&
          this.state.stationCycleMapping.length > 0
        ) {
          var verifyBtn = this.verifyCycleButton();
          result.push(verifyBtn);
        }
        if (this.state.stationCycleMapping) {
          var mapping = this.stationCycleMappingItems();
          result.push(mapping);
          var generateSummary = this.generateSummaryReportButton();
          result.push(generateSummary);
        }
      }
    }

    return result;
  }

  render() {
    let reportTypeDropdown = this.renderReportType();

    let reportDetailDropdown = this.state.reportType
      ? this.state.reportType == 1
        ? this.basicReportTypeForm()
        : this.summaryReportTypeForm()
      : "";

    let spinner = this.renderSpinner();
    return (
      <div>
        <h1 id="tabelLabel">Reports</h1>
        <p>This page can be used to generate reports based on collected data</p>
        {reportTypeDropdown}
        {reportDetailDropdown}
        {spinner}
      </div>
    );
  }

  testDetails() {
    return (
      <>
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          <div>
            <TextField
              sx={{ marginTop: "20px", marginRight: "20px" }}
              id="testdetails-input-1"
              label="UniqueSampleId"
              multiline
              onChange={this.handleTestDetails}
              maxRows={4}
            />
            <TextField
              sx={{ marginTop: "20px" }}
              id="testdetails-input-1"
              label="Comments"
              multiline
              onChange={this.handleComments}
              maxRows={4}
            />
          </div>
        </ThemeProvider>
      </>
    );
  }
  panelDropdown() {
    var panels = [];
    _appConfigPanels.map((element) => {
      panels.push(<MenuItem value={element}>{element}</MenuItem>);
    });
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{
            minWidth: 200,
            marginTop: "20px",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        >
          <InputLabel id="demo-simple-select-label">Select Panel</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.panelId}
            label="Select Panel"
            onChange={this.handlePanelChange}
          >
            {panels}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  stationDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{ minWidth: 200, marginTop: "20px", marginBottom: "20px" }}
        >
          <InputLabel id="demo-simple-select-label">Select Stations</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple={true}
            label="Select Stations"
            value={this.state.stations}
            onChange={this.handleStationSelection}
          >
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  renderGenerateReportButton() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <Button
          sx={{ marginTop: "15px" }}
          variant="outlined"
          disabled={this.state.isLoadingRawReport}
          onClick={this.fetchRawReportData}
        >
          Generate Report
        </Button>
      </ThemeProvider>
    );
  }
  renderSpinner() {
    return (
      (this.state.isLoadingRawReport || this.state.isLoadingSummaryReport) && (
        <LocalSpinner />
      )
    );
  }
  skipNSecondsInput() {
    return (
      <div>
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          <TextField
            sx={{ marginTop: "20px" }}
            type="number"
            id={"skipnseconds-btn"}
            label="Skip seconds since cycle start"
            value={this.state.skipNSeconds}
            onChange={this.handleSkipNSeconds}
          />
        </ThemeProvider>
      </div>
    );
  }
  fetchCyclesButton() {
    return (
      <Button
        sx={{ marginTop: "15px" }}
        variant="outlined"
        onClick={this.fetchCyclesData}
      >
        Get Operation On Cycles
      </Button>
    );
  }
  cyclesDropdown() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl sx={{ minWidth: 150, marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-label">Select Cycle</InputLabel>
          <Select
            labelId="select-station"
            id="select-station"
            value={this.state.mainCycle}
            label="Select Cycle"
            onChange={this.handleMainCycle}
          >
            {this.state.cycles.map((opt) => (
              <MenuItem value={opt}>{opt}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  stationCycleMappingItems() {
    var mappingItems = this.state.stationCycleMapping.map((station, index) => {
      return (
        <div style={{ marginTop: "10px" }}>
          <TextField
            sx={{ width: 100 }}
            disabled
            id={"btn1-" + index}
            label="Station"
            value={station.stationId}
          />
          <Select
            labelId="Select-Cycle"
            id={"btn2-" + index}
            value={station.cycleNo}
            label="Select Cycle"
            onChange={(e) => this.handleStationMappingCycle(index, e)}
          >
            {this.state.cycles.map((opt) => (
              <MenuItem value={opt}>{opt}</MenuItem>
            ))}
          </Select>
          <TextField
            disabled
            id={"btn3-" + index}
            label="Test start"
            value={
              station.testStart
                ? new Date(parseInt(station.testStart) * 1000).toLocaleString()
                : " "
            }
          />
          <TextField
            sx={{ width: 250 }}
            disabled
            error={station.error}
            id={"btn5-" + index}
            label="Error Check"
            value={
              station.error
                ? "Error detected / Not verified"
                : "No errors detected"
            }
          />
        </div>
      );
    });
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        {mappingItems}
      </ThemeProvider>
    );
  }
  verifyCycleButton() {
    return (
      <Button
        sx={{ marginTop: "15px", height: "40px" }}
        variant="outlined"
        onClick={this.verifyCyclesData}
      >
        Verify cycles
      </Button>
    );
  }
  generateSummaryReportButton() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <Button
          sx={{ marginTop: "15px", height: "40px" }}
          variant="outlined"
          onClick={this.fetchSummaryReportData}
          disabled={this.state.isLoadingSummaryReport}
        >
          Generate Report
        </Button>
      </ThemeProvider>
    );
  }

  handleChange(event) {
    this.setState((prev) => {
      prev.formInput[event.target.name] = event.target.value;
      return prev;
    });
  }
  handleChangeOption(event) {
    this.setState({
      timeRangeOption: event.target.value,
      cycles: null,
      mainCycle: null,
      stationCycleMapping: null,
    });
  }
  handleChangeFrom(val) {
    this.setState({
      from: val,
      cycles: null,
      mainCycle: null,
      stationCycleMapping: null,
    });
  }
  handleChangeTo(val) {
    this.setState({
      to: val,
      cycles: null,
      mainCycle: null,
      stationCycleMapping: null,
    });
  }
  handlePanelChange(event) {
    this.setState({
      panelId: event.target.value,
      cycles: null,
      mainCycle: null,
      stationCycleMapping: null,
    });
  }
  handleStationSelection(event) {
    var value = event.target.value;
    if (value.includes(0)) {
      value = this.stationList;
    }

    this.setState({
      stations: value,
      cycles: null,
      mainCycle: null,
      stationCycleMapping: null,
    });
  }
  handleTestDetails(event) {
    this.setState({ testDetails: event.target.value });
  }
  handleReportType(event) {
    this.setState({ reportType: event.target.value });
  }
  handleComments(event) {
    this.setState({ comments: event.target.value });
  }
  handleSkipNSeconds(event) {
    this.setState({ skipNSeconds: event.target.value });
  }
  handleMainCycle(event) {
    var cycle = event.target.value;
    this.setState({ mainCycle: cycle, stationCycleMapping: null });
    this.createStationCycleMapping(cycle);
  }
  createStationCycleMapping(cycle) {
    var cycle = cycle;
    var stations = this.state.stations;

    if (cycle && stations && stations.length > 0) {
      var mapping = stations.map((station) => {
        return {
          stationId: station,
          cycleNo: cycle,
          testStart: null,
          error: true,
        };
      });
      this.setState({ stationCycleMapping: mapping });
    }
  }
  handleStationMappingCycle(index, event) {
    var mapping = this.state.stationCycleMapping;
    mapping[index].cycleNo = event.target.value;
    mapping[index].error = true;
    mapping[index].minDate = null;
    mapping[index].maxDate = null;
    this.setState({ stationCycleMapping: mapping });
  }

  async fetchRawReportData() {
    this.setState({
      isLoadingRawReport: true,
      isLoadingSummaryReport: false,
    });

    var data = {
      locationId: this.state.locationId,
      panelId: this.state.panelId,
      stations: this.state.stations,
      timeRangeOption: this.state.timeRangeOption,
      from: this.state.from,
      to: this.state.to,
      comments: this.state.comments,
      testDetails: this.state.testDetails,
    };

    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      };
      const response = await fetch("data/GetRawReportData", requestOptions);
      const responseData = await response.json();

      var csvContent = responseData;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objUrl);
      link.setAttribute(
        "download",
        `RawReport_${new Date().toISOString()}.csv`
      );
      document.body.appendChild(link);
      link.click();
    }
    this.setState({
      isLoadingRawReport: false,
    });
  }
  async fetchCyclesData() {
    var data = {
      locationId: this.state.locationId,
      panelId: this.state.panelId,
      reportType: this.state.reportType,
      stations: this.state.stations,
      timeRangeOption: this.state.timeRangeOption,
      from: this.state.from,
      to: this.state.to,
    };

    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      };
      const response = await fetch("data/GetStationCycles", requestOptions);
      const responseData = await response.json();
      this.setState({ cycles: responseData });
    }
  }
  async verifyCyclesData() {
    var data = {
      locationId: this.state.locationId,
      panelId: this.state.panelId,
      timeRangeOption: this.state.timeRangeOption,
      from: this.state.from,
      to: this.state.to,
      mapping: this.state.stationCycleMapping,
    };

    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      };
      const response = await fetch("data/VerifyStationCycles", requestOptions);
      const responseData = await response.json();
      this.setState({ stationCycleMapping: responseData });
    }
  }
  async fetchSummaryReportData() {
    this.setState({
      isLoadingSummaryReport: true,
      isLoadingRawReport: false,
    });
    try {
      var data = {
        locationId: this.state.locationId,
        panelId: this.state.panelId,
        timeRangeOption: this.state.timeRangeOption,
        from: this.state.from,
        to: this.state.to,
        mapping: this.state.stationCycleMapping,
        skip: this.state.skipNSeconds,
        comments: this.state.comments,
        testDetails: this.state.testDetails,
      };

      const msalInstance = this.context.instance;
      const isAuthenticated = this.context.accounts.length > 0;
      if (isAuthenticated) {
        const token = await msalInstance.acquireTokenSilent({
          account: this.context.accounts[0],
          ...msalRequest,
        });
        var requestOptions = {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        };
        const response = await fetch(
          "data/GetSummaryReportData",
          requestOptions
        );
        const responseData = await response.json();

        var csvContent = responseData;
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8,",
        });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objUrl);
        link.setAttribute(
          "download",
          `SummaryReport_${new Date().toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch {
    } finally {
      this.setState({
        isLoadingSummaryReport: false,
      });
    }
  }
}
