import React, { Component } from "react";
import { WidgetContainer } from "./shared/WidgetContainer/WidgetContainer";
import { Link } from "react-router-dom";
import enduranceTestLogo from "../icons/logo.png";

export class Home extends Component {
  static displayName = Home.name;
  smallSize = {
    height: 300,
    width: 300,
    minWidth: 300,
  };
  render() {
    return (
      <div
        style={{
          margin: "20px 0 0 0",
        }}
      >
        <WidgetContainer
          size={this.smallSize}
          title={"Endurance Test"}
        >
          <a href="/dashboard">
            <img style={{width: "200px"}}src={enduranceTestLogo} alt="Endurance Test Icon"></img>
          </a>
        </WidgetContainer>
      </div>
    );
  }
}
