import React, { Component } from "react";
import "./ChartWidgets.scss";
import { ChartWidgetContainer } from "../../shared/ChartWidgetContainer/ChartWidgetContainer";
import { fromFlux } from "@influxdata/giraffe";
import LineChartRenderer from "../../widgets/LineChartMulti/LineChartRenderer";
import { _devmode_global } from "../../../appConfig";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../../api-authorization/msalconfig";

const REASONABLE_API_REFRESH_RATE = 30000;
const _devMode = _devmode_global;

export class ChartWidgets extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      panelId: this.props.panelId,
      stationId: this.props.station,
      testStart: this.props.testStart,
      from: this.props.from,
      to: this.props.to,
      timeRangeOption: this.props.timeRangeOption,

      voltage: null,
      current: null,
      temperature: null,
      power: null,
      all: null,
      stateChange: null,

      voltageInterval: 0,
      currentInterval: 0,
      temperatureInterval: 0,
      powerInterval: 0,
      allInterval: 0,
      stateChangeInterval: 0,

      voltageCSV: null,
      currentCSV: null,
      temperatureCSV: null,
      powerCSV: null,
      allCSV: null,
      stateChangeCSV: null,
    };

    this.handleVoltageInterval = this.handleVoltageInterval.bind(this);
    this.handleCurrentInterval = this.handleCurrentInterval.bind(this);
    this.handlePowerInterval = this.handlePowerInterval.bind(this);
    this.handleTemperatureInterval = this.handleTemperatureInterval.bind(this);
    this.handleStateInterval = this.handleStateInterval.bind(this);
    this.handleAllInterval = this.handleAllInterval.bind(this);
  }

  standardSize = {
    height: "550px",
    width: "98.5%",
  };

  componentDidMount() {
    this.getAllData();
    if (!_devMode) {
      const intervalID = setInterval(() => {
        this.getAllData();
      }, REASONABLE_API_REFRESH_RATE);

      this.setState({ interval: intervalID });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.panelId != state.panelId ||
      props.station != state.stationId ||
      props.testStart != state.testStart ||
      props.from != state.from ||
      props.to != state.to ||
      props.timeRangeOption != state.timeRangeOption
    ) {
      return {
        panelId: props.panelId,
        stationId: props.station,
        testStart: props.testStart,
        from: props.from,
        to: props.to,
        timeRangeOption: props.timeRangeOption,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.panelId != prevProps.panelId ||
      this.props.station != prevProps.station ||
      this.props.testStart != prevProps.testStart ||
      this.props.timeRangeOption != prevProps.timeRangeOption
    ) {
      this.getAllData();
    } else if (
      this.props.timeRangeOption === 0 &&
      (this.props.from != prevProps.from || this.props.to != prevProps.to) &&
      this.props.from != null &&
      this.props.to != null
    ) {
      this.getAllData();
    }
  }

  render() {
    let allWidget;
    if (this.state.all) {
      allWidget = (
        <ChartWidgetContainer
          size={this.standardSize}
          title="V|A|W|°C"
          onIntervalChange={this.handleAllInterval}
          exportData={this.state.allCSV}
          stationId={this.state.stationId}
        >
          <LineChartRenderer table={this.state.all} chartType="all" />
        </ChartWidgetContainer>
      );
    }

    let voltageWidget;
    if (this.state.voltage) {
      voltageWidget = (
        <ChartWidgetContainer
          size={this.standardSize}
          title="Voltage (V)"
          onIntervalChange={this.handleVoltageInterval}
          exportData={this.state.voltageCSV}
          stationId={this.state.stationId}
        >
          <LineChartRenderer table={this.state.voltage} chartType="voltage" />
        </ChartWidgetContainer>
      );
    }

    let currentWidget;
    if (this.state.current) {
      currentWidget = (
        <ChartWidgetContainer
          size={this.standardSize}
          title="Current (A)"
          onIntervalChange={this.handleCurrentInterval}
          exportData={this.state.currentCSV}
          stationId={this.state.stationId}
        >
          <LineChartRenderer table={this.state.current} chartType="current" />
        </ChartWidgetContainer>
      );
    }

    let powerWidget;
    if (this.state.power) {
      powerWidget = (
        <ChartWidgetContainer
          size={this.standardSize}
          title="Power (W)"
          onIntervalChange={this.handlePowerInterval}
          exportData={this.state.powerCSV}
          stationId={this.state.stationId}
        >
          <LineChartRenderer table={this.state.power} chartType="power" />
        </ChartWidgetContainer>
      );
    }

    let temperatureWidget;
    if (this.state.temperature) {
      temperatureWidget = (
        <ChartWidgetContainer
          size={this.standardSize}
          title="Temperature (°C)"
          onIntervalChange={this.handleTemperatureInterval}
          exportData={this.state.temperatureCSV}
          stationId={this.state.stationId}
        >
          <LineChartRenderer
            table={this.state.temperature}
            chartType="temperature"
          />
        </ChartWidgetContainer>
      );
    }
    let stateChangeWidget;
    if (this.state.stateChange) {
      stateChangeWidget = (
        <ChartWidgetContainer
          size={this.standardSize}
          title="State Change"
          onIntervalChange={this.handleStateInterval}
          exportData={this.state.stateChangeCSV}
          stationId={this.state.stationId}
        >
          <LineChartRenderer table={this.state.stateChange} chartType="fault" />
        </ChartWidgetContainer>
      );
    }
    return (
      <>
        {allWidget}
        {voltageWidget}
        {currentWidget}
        {powerWidget}
        {temperatureWidget}
        {stateChangeWidget}
      </>
    );
  }

  getAllData() {
    if (this.props.autoRefresh) {
      this.fetchAllWidgetData();
      this.fetchVoltageData();
      this.fetchCurrentData();
      this.fetchPowerData();
      this.fetchTemperatureData();
      this.fetchStateChangeData();
    }
  }

  async fetchVoltageData(intervalOption = null) {
    const responseData = await this.fetchData(1, intervalOption);
    this.setState({ voltage: responseData });
  }
  async fetchCurrentData(intervalOption = null) {
    const responseData = await this.fetchData(2, intervalOption);
    this.setState({ current: responseData });
  }
  async fetchPowerData(intervalOption = null) {
    const responseData = await this.fetchData(3, intervalOption);
    this.setState({ power: responseData });
  }
  async fetchTemperatureData(intervalOption = null) {
    const responseData = await this.fetchData(4, intervalOption);
    this.setState({ temperature: responseData });
  }
  async fetchStateChangeData(intervalOption = null) {
    const responseData = await this.fetchData(5, intervalOption);
    this.setState({ stateChange: responseData });
  }
  async fetchAllWidgetData(intervalOption = null) {
    const responseData = await this.fetchData(6, intervalOption);
    this.setState({ all: responseData });
  }
  async fetchData(type, intervalValue = null) {
    var from = this.state.from;
    var to = this.state.to;
    var option = this.state.timeRangeOption;
    var panelId = this.state.panelId;
    var stationId = this.state.stationId;
    var testStart = this.state.testStart;
    var interval = null;

    if (intervalValue == null) {
      switch (type) {
        case 1:
          interval = this.state.voltageInterval;
          break;
        case 2:
          interval = this.state.currentInterval;
          break;
        case 3:
          interval = this.state.powerInterval;
          break;
        case 4:
          interval = this.state.temperatureInterval;
          break;
        case 5:
          interval = this.state.stateChangeInterval;
          break;
        case 6:
          interval = this.state.allInterval;
          break;
      }
    } else {
      interval = intervalValue;
    }

    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/csv",
        },
        method: "GET",
      };
      const response = await fetch(
        `data/getChartData?stationId=${stationId}&testStart=${testStart}&chartType=${type}&from=${
          from ?? ""
        }&to=${to ?? ""}&timeRangeOption=${option}&timeGrainOption=${interval}&panelId=${panelId}`,
        requestOptions
      );

      const resultsCSV = await response.text();

      let results;
      try {
        results = fromFlux(resultsCSV).table;
        switch (type) {
          case 1:
            this.setState({ voltageCSV: resultsCSV });
            break;
          case 2:
            this.setState({ currentCSV: resultsCSV });
            break;
          case 3:
            this.setState({ powerCSV: resultsCSV });
            break;
          case 4:
            this.setState({ temperatureCSV: resultsCSV });
            break;
          case 5:
            this.setState({ stateChangeCSV: resultsCSV });
            break;
          case 6:
            this.setState({ allCSV: resultsCSV });
            break;
        }
      } catch (error) {
        console.error("error", error.message);
      }

      return results;
    }
  }

  handleVoltageInterval(intervalOption) {
    this.setState({ voltageInterval: intervalOption });
    this.fetchVoltageData(intervalOption);
  }
  handleCurrentInterval(intervalOption) {
    this.setState({ currentInterval: intervalOption });
    this.fetchCurrentData(intervalOption);
  }
  handlePowerInterval(intervalOption) {
    this.setState({ powerInterval: intervalOption });
    this.fetchPowerData(intervalOption);
  }
  handleTemperatureInterval(intervalOption) {
    this.setState({ temperatureInterval: intervalOption });
    this.fetchTemperatureData(intervalOption);
  }
  handleStateInterval(intervalOption) {
    this.setState({ stateChangeInterval: intervalOption });
    this.fetchStateChangeData(intervalOption);
  }
  handleAllInterval(intervalOption) {
    this.setState({ allInterval: intervalOption });
    this.fetchAllWidgetData(intervalOption);
  }
}
