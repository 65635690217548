import { Dashboard } from './components/Dashboard/Dashboard';
import { Home } from "./components/Home";
import { Reports } from './components/Reports/Reports';
import { Comparison } from './components/Comparison/Comparison';
import StationDrillthrough from './components/StationDetails/StationDrillthrough';
import { OffsetConfig } from './components/offset/OffsetConfig';
import { Notifications } from './components/notifications/Notifications';
import { Rules } from './components/rules/Rules';
import { About } from './components/About/About';

const AppRoutes = [
  {
    index: true,
    requireAuth: false,
    element: <Home />
  },
  {
    path: '/Dashboard',
    requireAuth: true,
    element: <Dashboard />
  },
  {
    path: '/Reports',
    requireAuth: true,
      element: <Reports />
  },
  {
    path: '/Comparison',
    requireAuth: true,
      element: <Comparison />
  },
  {
    path: '/details/:locationId/:panelId/:stationId',
    requireAuth: true,
    element: <StationDrillthrough />
  },
  {
    path: '/offsets',
    requireAuth: true,
    element: <OffsetConfig />
  },
  {
    path: '/notifications',
    requireAuth: true,
    element: <Notifications />
  },
  {
    path: '/rules',
    requireAuth: true,
    element: <Rules />
  },
  {
    path: '/about',
    requireAuth: true,
    element: <About />
  },
];

export default AppRoutes;
