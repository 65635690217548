import React, { Component } from "react";
import "./WidgetContainer.scss"

export class WidgetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div
        className="widget-container-component"
        style={this.props.size}
      >
        <span className="title">{this.props.title}</span>
        <div className="widget">{this.props.children}</div>
      </div>
    );
  }
}
