import React, { Component } from "react";
import "./TestDetailsText.scss";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../../api-authorization/msalconfig";
import { ThemeProvider, createTheme, Button, TextField } from "@mui/material";

export class TestDetailsText extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      originalText: this.props.testDetailText || "--",
      editedText: "",
      inEditState: false,
    };

    this.handleEditText = this.handleEditText.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  componentDidMount() {}

  render() {
    var view = this.state.inEditState ? this.editView() : this.readOnlyView();
    return <div className="testdetailstext-container">{view}</div>;
  }

  editView() {
    return (
        <div className="edit-mode">
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: "dark",
              },
            })}
          >
            <div>
              <TextField
                sx={{ marginTop: "20px" }}
                id="testdetailstext-input-1"
                label="Test Details"
                multiline
                onChange={this.handleEditText}
                maxRows={4}
                value={this.state.editedText}
              />
            </div>
            <div>
              <Button
                sx={{ marginTop: "15px" }}
                variant="outlined"
                onClick={this.handleEditSubmit}
              >
                Save
              </Button>
              <Button
                sx={{ marginTop: "15px" }}
                variant="outlined"
                onClick={() => this.setState({ inEditState: false })}
              >
                Cancel
              </Button>
            </div>
          </ThemeProvider>
        </div>
    );
  }

  readOnlyView() {
    var text = this.state.originalText;
    return (
      <div className="readonly-mode">
        <span className="actual-text">{text.length > 7 ? text.substring(0, 7) + ".." : text}</span>
        <span className="tooltiptext">{text}</span>

        <button
          className="edit-text-btn"
          onClick={() => this.setState({ inEditState: true, editedText: this.state.originalText })}
        >
          ✎
        </button>
      </div>
    );
  }

  handleEditText(event) {
    this.setState({ editedText: event.target.value });
  }

  async handleEditSubmit() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          locationId: this.props.locationId,
          panelId: this.props.panelId,
          stationId: this.props.stationId,
          text: this.state.editedText,
        }),
      };
      const response = await fetch("data/SetTestDetailsText", requestOptions);
      const data = await response.json();
      this.setState({
        inEditState: false,
        originalText: data,
      });
    }
  }
}
