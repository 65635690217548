import React, { Component } from "react";
import { DateRange } from "../shared/DateRange/DateRange";
import {
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  createTheme,
  MenuItem,
  Button,
} from "@mui/material";
import { _appConfigPanels } from "../../appConfig";
import { ChartWidgetContainer } from "../shared/ChartWidgetContainer/ChartWidgetContainer";
import LineChartRenderer from "../widgets/LineChartMulti/LineChartRenderer";
import "./Comparison.scss";
import { fromFlux } from "@influxdata/giraffe";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";

export class Comparison extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      panelId: 1,
      station1: 1,
      station2: 2,
      property1: 1,
      property2: 2,
      timeRangeOption: 7,
      interval: 0,
      from: null,
      to: null,

      data: null,
      csv: null,
      isLoading: false,
    };
    this.handlePanelChange = this.handlePanelChange.bind(this);
    this.handleStationChange1 = this.handleStationChange1.bind(this);
    this.handleStationChange2 = this.handleStationChange2.bind(this);
    this.handleProperty1 = this.handleProperty1.bind(this);
    this.handleProperty2 = this.handleProperty2.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handleInterval = this.handleInterval.bind(this);
  }

  standardSize = {
    height: "550px",
    width: "98.5%",
  };

  render() {
    return (
      <div className="comparison-page-container">
        <div>
          {this.panelDropdown()}
          {this.stationDropdown(true)}
          {this.stationDropdown(false)}
          {this.propertyDropdown(true)}
          {this.propertyDropdown(false)}
        </div>
        <div>
          {this.timeRange()}
          {this.submitButton()}
        </div>
        {this.state.data && !this.state.isLoading && (
          <ChartWidgetContainer
            size={this.standardSize}
            title="Comparison"
            onIntervalChange={this.handleInterval}
            exportData={this.state.csv}
            stationId={this.state.station1}
          >
            <LineChartRenderer table={this.state.data} chartType="comparison" />
          </ChartWidgetContainer>
        )}
      </div>
    );
  }

  stationDropdown(first = true) {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{ minWidth: 120, marginRight: "10px", marginTop: "20px" }}
        >
          <InputLabel id="demo-simple-select-label">
            Select Station {first ? "1" : "2"}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={first ? this.state.station1 : this.state.station2}
            label="Select Station __"
            onChange={
              first ? this.handleStationChange1 : this.handleStationChange2
            }
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }

  propertyDropdown(first = true) {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{ minWidth: 150, marginRight: "10px", marginTop: "20px" }}
        >
          <InputLabel id="demo-simple-select-label">
            Select Property {first ? "1" : "2"}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={first ? this.state.property1 : this.state.property2}
            label="Select Property ___"
            onChange={first ? this.handleProperty1 : this.handleProperty2}
          >
            <MenuItem value={1}>Voltage</MenuItem>
            <MenuItem value={2}>Current</MenuItem>
            <MenuItem value={3}>Power</MenuItem>
            <MenuItem value={4}>Temperature</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }

  timeRange() {
    return (
      <span className="timerange">
        <DateRange
          timeRangeOption={this.state.timeRangeOption}
          handleChangeOption={this.handleChangeOption}
          handleChangeFrom={this.handleChangeFrom}
          handleChangeTo={this.handleChangeTo}
        />
      </span>
    );
  }

  submitButton() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <Button
          variant="outlined"
          className="interval-dropdown"
          onClick={this.fetchData}
          disabled={this.state.isLoading}
          sx={{
            minWidth: 150,
            height: "55px",
            marginLeft: "20px",
            marginTop: "20px",
          }}
        >
          Generate
        </Button>
      </ThemeProvider>
    );
  }

  panelDropdown() {
    var panels = [];
    _appConfigPanels.map((element) => {
      panels.push(<MenuItem value={element}>{element}</MenuItem>);
    });
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl
          sx={{ minWidth: 120, marginRight: "10px", marginTop: "20px" }}
        >
          <InputLabel id="demo-simple-select-label">Select Panel</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.panelId}
            label="Select Panel"
            onChange={this.handlePanelChange}
          >
            {panels}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }

  handleStationChange1(event) {
    this.setState({ station1: event.target.value });
  }
  handleStationChange2(event) {
    this.setState({ station2: event.target.value });
  }
  handleProperty1(event) {
    this.setState({ property1: event.target.value });
  }
  handleProperty2(event) {
    this.setState({ property2: event.target.value });
  }
  handleChangeOption(event) {
    this.setState({
      timeRangeOption: event.target.value,
    });
  }
  handleChangeFrom(val) {
    this.setState({
      from: val,
    });
  }
  handleChangeTo(val) {
    this.setState({
      to: val,
    });
  }
  handleInterval(intervalOption) {
    this.setState({ interval: intervalOption });
    this.fetchData(intervalOption);
  }
  handlePanelChange(event) {
    this.setState({ panelId: event.target.value });
  }

  async fetchData(intervalOption) {
    this.setState({
      isLoading: true,
    });
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/csv",
        },
        method: "GET",
      };
      var panelId = this.state.panelId;
      var station1 = this.state.station1;
      var station2 = this.state.station2;
      var property1 = this.state.property1;
      var property2 = this.state.property2;
      var interval = intervalOption ?? this.state.interval;
      var timeRangeOption = this.state.timeRangeOption;
      var from = this.state.from;
      var to = this.state.to;

      const response = await fetch(
        `data/getComparisonData?station1=${station1}&station2=${station2}&property1=${property1}&property2=${property2}&from=${
          from ?? ""
        }&to=${
          to ?? ""
        }&timeRangeOption=${timeRangeOption}&timeGrainOption=${interval}&panelId=${panelId}`,
        requestOptions
      );

      const resultsCSV = await response.text();
      try {
        var table = fromFlux(resultsCSV).table;
        this.setState({ data: table });
        this.setState({ csv: resultsCSV });
        this.setState({ isLoading: false });
      } catch (error) {
        console.error("error", error.message);
      }
    }
  }
}
