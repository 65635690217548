import React, { Component } from "react";
import { Plot } from "@influxdata/giraffe";
import { TextField, ThemeProvider, createTheme } from "@mui/material";
import "./LineChart.scss";

export default class LineChartRenderer extends Component {
  constructor(props) {
    super(props);
    var localYMin = localStorage.getItem(`${this.props.chartType}-ymin`);
    var localYMax = localStorage.getItem(`${this.props.chartType}-ymax`);
    this.state = {
      yMin: localYMin ?? this.themes[this.props.chartType].yMin,
      yMax: localYMax ?? this.themes[this.props.chartType].yMax,
    };

    this.handleYMinChange = this.handleYMinChange.bind(this);
    this.handleYMaxChange = this.handleYMaxChange.bind(this);
  }

  themes = {
    "": { color: ["#FF6D60", "#FF6D60"], axisLabel: "", suffix: "" },
    all: {
      color: ["#FF6D60", "#29AB87", "#009EFF", "#FF8400"],
      axisLabel: "V | A | W | °C",
      suffix: "",
      fill: ["StationCycles", "_field"],
      yMin: 0,
      yMax: 1250,
    },
    voltage: {
      color: ["#FF6D60", "#FF6D60"],
      axisLabel: "Voltage",
      suffix: "V",
      fill: ["StationCycles"],
      yMin: 0,
      yMax: 250,
    },
    current: {
      color: ["#29AB87", "#29AB87"],
      axisLabel: "Current",
      suffix: "A",
      fill: ["StationCycles"],
      yMin: 0,
      yMax: 7,
    },
    power: {
      color: ["#009EFF", "#009EFF"],
      axisLabel: "Power",
      suffix: "W",
      fill: ["StationCycles"],
      yMin: 0,
      yMax: 1250,
    },
    temperature: {
      color: ["#FF8400", "#FF8400"],
      axisLabel: "Temperature",
      suffix: "°C",
      fill: ["StationCycles"],
      yMin: 0,
      yMax: 250,
    },
    fault: {
      color: ["#FF8400", "#FF8400"],
      axisLabel: "Fault Code",
      suffix: "",
      fill: ["StationCycles"],
      yMin: 0,
      yMax: 3,
    },
    comparison: {
      axisLabel: "",
      suffix: "",
      fill: ["Station", "_field", "StationCycles", "TestStarted"],
      yMin: 0,
      yMax: 1250,
    },
  };

  render() {
    const style = {
      height: "80%",
      width: "95%",
      margin: "20px",
    };
    var theme = this.themes[this.props.chartType ?? ""];
    var baseLayer = {
      type: "line",
      x: "_time",
      y: "_value",
      shadeBelow: true,
      interpolation: "linear",
      fill: [...theme.fill],
      hoverDimension: "x"
    };
    
    if (theme.color) {
      baseLayer.colors = theme.color;
    }

    const config = {
      table: this.props.table,
      gridColor: "rgba(255,255,255,0.3)",
      axisColor: "rgba(255,255,255,0.3)",
      tickFontColor: "rgba(255,255,255,0.85)",
      xAxisLabel: "Time",
      yAxisLabel: theme.axisLabel,
      valueFormatters: {
        _value: (num) => num.toFixed(0) + theme.suffix,
      },
      yDomain: [parseFloat(this.state.yMin), parseFloat(this.state.yMax)],
      includeYDomainZoom: false,
      legendFont: "18px monospace",
      layers: [
        baseLayer  
      ],
    };

    return (
      <>
        <div style={style}>
          <Plot config={config} />
        </div>
        <div>{this.minMaxFields()}</div>
      </>
    );
  }

  minMaxFields() {
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <TextField
          sx={{
            width: 80,
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "-15px",
          }}
          id="standard-number1"
          label="yMin"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          value={this.state.yMin}
          onChange={this.handleYMinChange}
        />
        <TextField
          sx={{ width: 80, marginTop: "-15px" }}
          id="standard-number2"
          label="yMax"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          value={this.state.yMax}
          onChange={this.handleYMaxChange}
        />
      </ThemeProvider>
    );
  }

  handleYMinChange(event) {
    this.setState({ yMin: event.target.value });
    localStorage.setItem(`${this.props.chartType}-ymin`, event.target.value);
  }
  handleYMaxChange(event) {
    this.setState({ yMax: event.target.value });
    localStorage.setItem(`${this.props.chartType}-ymax`, event.target.value);
  }
}
