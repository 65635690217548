import { Component } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ThemeProvider,
  createTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

export class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRangeOption: this.props.timeRangeOption,
    };

    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
  }

  handleChangeOption(event) {
    this.setState({
      timeRangeOption: event.target.value,
    });
    this.props.handleChangeOption(event);
  }
  handleChangeFrom(val) {
    this.props.handleChangeFrom(val);
  }
  handleChangeTo(val) {
    this.props.handleChangeTo(val);
  }

  render() {
    let showCustomRange = this.state.timeRangeOption === 0;
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="demo-simple-select-label">
            Select Time Period
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.timeRangeOption}
            label="Select Time Period"
            onChange={this.handleChangeOption}
          >
            <MenuItem value={0}>Custom Time Range</MenuItem>
            <MenuItem value={1}>Past 1m</MenuItem>
            <MenuItem value={2}>Past 5m</MenuItem>
            <MenuItem value={3}>Past 15m</MenuItem>
            <MenuItem value={4}>Past 1h</MenuItem>
            <MenuItem value={5}>Past 3h</MenuItem>
            <MenuItem value={6}>Past 6h</MenuItem>
            <MenuItem value={7}>Past 12h</MenuItem>
            <MenuItem value={8}>Past 1d</MenuItem>
            <MenuItem value={9}>Past 3d</MenuItem>
            <MenuItem value={10}>Past 7d</MenuItem>
            <MenuItem value={11}>Past 30d</MenuItem>
          </Select>
        </FormControl>
        {showCustomRange && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="From"
              value={this.state.from}
              onChange={this.handleChangeFrom}
            />
            <DateTimePicker
              label="To"
              value={this.state.to}
              onChange={this.handleChangeTo}
            />
          </LocalizationProvider>
        )}
      </ThemeProvider>
    );
  }
}
