export const pcaConfig = {
  auth: {
    //clientId: "26b8847a-ad26-4715-ac33-e8cb9e4cd3cf",
    //redirectUri: "https://localhost:44464/",
     clientId: "29e92a1a-f9c8-475c-ad27-aeb7f3e62ecb",
     redirectUri: "https://starwattiot.in/dashboard",
    authority:
      "https://login.microsoftonline.com/10ef0238-97a3-42cd-ac00-ba01593217ce",
  },
};

export const msalRequest = {
  scopes: [
    "https://starwatt.onmicrosoft.com/0f1617fd-64c9-475b-a761-5885d25ecea0/Data.read",
  ],
};