import React, { Component } from "react";
import "./ErrorCodeIndicator.scss";

export class ErrorCodeIndicator extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  mapping(count) {
    if (count === 0) return "green";
    else return "red";
  }

  hoverMapping(val) {
    if (val === 1) return "E1: Electricity failure";
    if (val === 2) return "E2: Iron failure";
    if (val === 3) return "E3: Temperature over limit (over 290°C)";
    if (val === 4) return "E4: Over current";
    return "";
  }

  render() {
    return (
      <div className="errorcode-indicator-container">
        <span
          className={`status-indicator dot ${this.mapping(
            this.props.errorCount
          )} ${this.props.size}`}
        >
          {this.props.errorCount}
          <span className="tooltiptext">
            {this.hoverMapping(this.props.errorCode)}
          </span>
        </span>
      </div>
    );
  }
}
