import React, { Component } from "react";
import "../SingleStation/SingleStation.scss";
import { StatusIndicator } from "../../widgets/StatusIndicator/StatusIndicator";
import { Gauge } from "../../widgets/Gauge/Gauge";

export class PanelInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  testStatusMapping(status) {
    if (status === 0) return "OFF";
    if (status === 1) return "ON";
    else return "--";
  }

  faultCodeMapping(code) {
    if (code === 0) return "E0: No issues detected";
    if (code === 1) return "E1: Electricity failure";
    if (code === 2) return "E2: Iron failure";
    if (code === 3) return "E3: Temperature over limit (over 290°C)";
    if (code === 4) return "E4: Over current";
    else return "Info not available";
  }

  render() {
    return (
      <div className="single-station">
        <span className="station-title">Panel Input</span>
        <hr></hr>
        <span className="widget-label">Input status</span>
        <span className="test-status-text">
          {this.testStatusMapping(this.props.details.status)}
        </span>
        <StatusIndicator size="medium" status={this.props.details.status} />

        <div className="widget-container">
          <center>
            <div className="widget-label">Input Voltage (V)</div>
          </center>
          <div className="widget">
            <Gauge
              data={this.props.details.voltage ?? 0}
              gaugeType={"voltage"}
              showTicks={false}
            />
          </div>
        </div>

        <div className="widget-container">
          <center>
            <div className="widget-label">Power Consumption</div>
            <div className="text-type-widget">
              {this.props.details.power ?? "--"}kWh
            </div>
          </center>
        </div>
        <div className="footer">
          <span className="widget-label">Last Updated: </span>
          <span className="widget-label">
            {this.props.details.time ?? "--"}
          </span>
        </div>
      </div>
    );
  }
}
