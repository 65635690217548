import React, { Component, Fragment } from "react";
import { MsalContext } from "@azure/msal-react";

export class LoginMenu extends Component {
  constructor(props) {
    super(props);
  }
  static contextType = MsalContext;

  render() {
    var styles = {
      background: "unset",
      border: "none",
      color: "rgba(255, 255, 255, 0.55)",
    };
    const isAuthenticated = this.context.accounts.length > 0;
    const msalInstance = this.context.instance;
    if (isAuthenticated) {
      return (
        <button style={styles} onClick={() => msalInstance.logout()}>
          {this.context.accounts[0].name} | Sign out
        </button>
      );
    } else {
      return (
        <button style={styles} onClick={() => msalInstance.loginRedirect()}>
          Login
        </button>
      );
    }
  }
}
