import React, { Component } from "react";
import { SingleStation } from "./SingleStation/SingleStation";
import "./Dashboard.css";
import { PanelInput } from "./PanelInput/PanelInput";
import { LocalSpinner } from "../shared/LocalSpinner/LocalSpinner";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../api-authorization/msalconfig";
import {
  _devmode_global,
  _appConfigStations,
  _appConfigPanels,
  _appConfigDefaultLocation,
} from "../../appConfig";
import {
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  createTheme,
  MenuItem,
} from "@mui/material";

const REASONABLE_API_REFRESH_RATE = 10000;
const _dashboardPanelCount = _appConfigStations;
const _devMode = _devmode_global;

export class Dashboard extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    var panelIdCacheValue = localStorage.getItem(`panelId`);
    this.state = {
      locationId: _appConfigDefaultLocation,
      panelId: panelIdCacheValue ?? 1,
      details: [],
      panelInput: {},
      loading: true,
    };
    this.handlePanelChange = this.handlePanelChange.bind(this);
  }

  componentDidMount() {
    this.fetchDashboardData();
    if (!_devMode) {
      const intervalID = setInterval(() => {
        this.fetchDashboardData();
      }, REASONABLE_API_REFRESH_RATE);

      this.setState({ interval: intervalID });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  panelDropdown() {
    var panels = [];
    _appConfigPanels.map((element) => {
      panels.push(<MenuItem value={element}>{element}</MenuItem>);
    });
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="demo-simple-select-label">Select Panel</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.panelId}
            label="Select Panel"
            onChange={this.handlePanelChange}
          >
            {panels}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
  handlePanelChange(event) {
    this.setState({ panelId: event.target.value });
    localStorage.setItem(`panelId`, event.target.value);
    this.setState({ loading: true });
    this.fetchDashboardData();
  }

  render() {
    var inputPanel = <PanelInput details={this.state.panelInput} />;
    var stations = this.state.details.map((station) => {
      return (
        <SingleStation
          locationId={this.state.locationId}
          panelId={this.state.panelId}
          key={station.name}
          details={{ ...station }}
        />
      );
    });
    var container = [inputPanel, ...stations];
    return (
      <div>
        <h1>Monitoring Dashboard</h1>
        {this.panelDropdown()}
        {this.state.loading ? (
          <LocalSpinner />
        ) : (
          <div className="station-container">{container}</div>
        )}
      </div>
    );
  }

  async fetchDashboardData() {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      };
      const response = await fetch(
        `data/getdashboarddata?locationId=${this.state.locationId}&panelId=${this.state.panelId}`,
        requestOptions
      );
      var responseData = await response.json();

      var paneldetail = {};
      if (
        responseData &&
        responseData.length > 0 &&
        responseData[0].name === 0
      ) {
        var panel = responseData.splice(0, 1);
        paneldetail = panel[0];
      }

      if (responseData && responseData.length < _dashboardPanelCount) {
        for (var i = responseData.length + 1; i <= _dashboardPanelCount; i++) {
          responseData.push({});
        }
      }
      this.setState({
        details: responseData,
        panelInput: paneldetail,
        loading: false,
      });
    }
  }
}
