import React, { Component } from "react";
import "./SingleStation.scss";
import { StatusIndicator } from "../../widgets/StatusIndicator/StatusIndicator";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Form, Button, CloseButton } from "react-bootstrap";
import { ErrorCodeIndicator } from "../../widgets/ErrorCodeIndicator/ErrorCodeIndicator";
import { MsalContext } from "@azure/msal-react";
import { msalRequest } from "../../api-authorization/msalconfig";
import { Gauge } from "../../widgets/Gauge/Gauge";
import { TestDetailsText } from "../../widgets/TestDetailsText/TestDetailsText";
const _cycleCountDefault = 50;

export class SingleStation extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      formInput: {
        locationId: this.props.locationId,
        panelId: this.props.panelId,
        stationId: this.props.details.name,
        value: null,
        setForAll: null,
      },
      editingTotalCycle: false,
    };

    this.handlEditTotalCycleChange = this.handlEditTotalCycleChange.bind(this);
    this.handleEditTotalCycleSubmit =
      this.handleEditTotalCycleSubmit.bind(this);
  }

  componentDidMount() {}

  testStatusMapping(status) {
    if (status === 0) return "OFF";
    if (status === 1) return "ON";
    else return "--";
  }

  faultCodeMapping(code) {
    if (code === 0) return "E0: No issues detected";
    if (code === 1) return "E1: Electricity failure";
    if (code === 2) return "E2: Iron failure";
    if (code === 3) return "E3: Temperature over limit (over 290°C)";
    if (code === 4) return "E4: Over current";
    else return "Info not available";
  }

  editTotalCycle() {
    return (
      <div className="edit-total-cycle-container">
        <Form className="mb-3" onSubmit={this.handleEditTotalCycleSubmit}>
          <CloseButton
            onClick={() => this.setState({ editingTotalCycle: false })}
            variant="white"
          />
          <Form.Group className="mb-3">
            <Form.Label>Enter Value</Form.Label>
            <Form.Control
              label="Enter Value"
              name="value"
              onChange={this.handlEditTotalCycleChange}
              type="number"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="All"
              name="setForAll"
              onChange={this.handlEditTotalCycleChange}
            />
          </Form.Group>
          <Button style={{ marginTop: "15px" }} type="submit">
            Submit
          </Button>
        </Form>
      </div>
    );
  }

  handlEditTotalCycleChange(event) {
    this.setState((prev) => {
      prev.formInput[event.target.name] = event.target.value;
      return prev;
    });
  }

  handleEditTotalCycleSubmit(event) {
    this.setTotalCycleData(this.state.formInput);
    event.preventDefault();
  }

  render() {
    var cycleCountDisplayText =
      this.props.details.cycleCount != null
        ? `${this.props.details.cycleCount}/${
            this.props.details.cycleCountTotal ?? _cycleCountDefault
          }`
        : "-/-";

    return (
      <div className="single-station">
        <a
          className="station-title"
          href={`/details/${this.props.locationId}/${this.props.panelId}/${this.props.details.name}`}
        >
          Station {this.props.details.name ?? "--"}
        </a>
        <hr></hr>
        <span className="widget-label">Test details</span>
        <TestDetailsText
          locationId={this.props.locationId}
          panelId={this.props.panelId}
          stationId={this.props.details.name}
          testDetailText={this.props.details.testDetail}
        />
        <span className="widget-label">Test status</span>
        <span className="test-status-text">
          {this.testStatusMapping(this.props.details.status)}
        </span>
        <StatusIndicator size="medium" status={this.props.details.status} />

        <div className="cycle-fault-container">
          <div>
            <center>
              <div className="widget-label">
                Cycle {this.testStatusMapping(this.props.details.cycleStatus)}
              </div>
              <StatusIndicator
                size="small"
                status={this.props.details.cycleStatus}
              />
            </center>
          </div>
          <div>
            <center>
              <div className="widget-label">Fault</div>
              <StatusIndicator
                size="small"
                status={this.props.details.faultStatus == 0 ? 1 : 2}
                hoverMessage={this.faultCodeMapping(
                  this.props.details.faultStatus
                )}
              />
            </center>
          </div>
        </div>

        <div className="fault-counter-container">
          <div>
            <center>
              <div className="widget-label">E1</div>
              <ErrorCodeIndicator
                size="smallks"
                errorCount={this.props.details.ec1 ?? 0}
                errorCode={1}
              />
            </center>
          </div>
          <div>
            <center>
              <div className="widget-label">E2</div>
              <ErrorCodeIndicator
                size="smallks"
                errorCount={this.props.details.ec2 ?? 0}
                errorCode={2}
              />
            </center>
          </div>
          <div>
            <center>
              <div className="widget-label">E3</div>
              <ErrorCodeIndicator
                size="smallks"
                errorCount={this.props.details.ec3 ?? 0}
                errorCode={3}
              />
            </center>
          </div>
          <div>
            <center>
              <div className="widget-label">E4</div>
              <ErrorCodeIndicator
                size="smallks"
                errorCount={this.props.details.ec4 ?? 0}
                errorCode={4}
              />
            </center>
          </div>
        </div>

        <div className="widget-container progress-bar-container">
          <center>
            <span className="widget-label">Cycle</span>
            <div className="widget-progress-bar">
              <ProgressBar
                now={this.props.details.cycleCount ?? 0}
                max={this.props.details.cycleCountTotal ?? _cycleCountDefault}
              />
            </div>
          </center>
          {this.state.editingTotalCycle && this.editTotalCycle()}
          {!this.state.editingTotalCycle && (
            <center>
              <span className="widget-progress-bar-label offset-edit-label">
                {cycleCountDisplayText}
              </span>
              <button
                className="edit-total-cycles-btn"
                onClick={() => this.setState({ editingTotalCycle: true })}
              >
                ✎
              </button>
            </center>
          )}
        </div>

        <div className="widget-container progress-bar-container">
          <center>
            <div className="widget-label">TS Cycle</div>
            <div className="widget-progress-bar">
              <ProgressBar
                now={this.props.details.thermostatCycle ?? 0}
                max={25000}
              />
            </div>
            <span className="widget-progress-bar-label">{`${
              this.props.details.thermostatCycle ?? "-"
            }`}</span>
          </center>
        </div>

        <div className="widget-container">
          <center>
            <div className="widget-label">Temperature (°C)</div>
          </center>
          <div className="widget">
            <Gauge
              data={this.props.details.temperature ?? 0}
              gaugeType={"temperature"}
              showTicks={false}
            />
          </div>
        </div>

        <div className="widget-container">
          <center>
            <div className="widget-label">Power (W)</div>
          </center>
          <div className="widget">
            <Gauge
              data={this.props.details.power ?? 0}
              gaugeType={"power"}
              showTicks={false}
            />
          </div>
        </div>

        <div className="widget-container">
          <center>
            <div className="widget-label">Voltage (V)</div>
          </center>
          <div className="widget">
            <Gauge
              data={this.props.details.voltage ?? 0}
              gaugeType={"voltage"}
              showTicks={false}
            />
          </div>
        </div>
        {/* <div className="footer">
          <span className="widget-label">Last Updated: </span>
          <span className="widget-label">
            {this.props.details.time ?? "--"}
          </span>
        </div> */}
      </div>
    );
  }

  async setTotalCycleData(data) {
    const msalInstance = this.context.instance;
    const isAuthenticated = this.context.accounts.length > 0;
    if (isAuthenticated) {
      const token = await msalInstance.acquireTokenSilent({
        account: this.context.accounts[0],
        ...msalRequest,
      });
      var requestOptions = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      };
      const response = await fetch("data/SetCycleData", requestOptions);
      this.setState({ editingTotalCycle: false });
    }
  }
}
