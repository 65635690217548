import React, { Component } from "react";
import "./ErrorCounter.scss";

export class ErrorCounter extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div className="error-counter-container">
        <p className="e1">E1 - {this.props.ec1 ?? 0}</p>
        <p className="e2">E2 - {this.props.ec2 ?? 0}</p>
        <p className="e3">E3 - {this.props.ec3 ?? 0}</p>
        <p className="e3">E4 - {this.props.ec4 ?? 0}</p>
      </div>
    );
  }
}